@import './global.scss';
@import './variables.scss';

p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 8.0pt;
  margin-left: 0cm;
  line-height: 107%;
  font-size: 11.0pt;
  font-family: "Calibri", sans-serif;
}

.MsoChpDefault {
  font-family: "Calibri", sans-serif;
}

.MsoPapDefault {
  margin-bottom: 8.0pt;
  line-height: 107%;
}

@page WordSection1 {
  size: 595.3pt 841.9pt;
  margin: 72.0pt 72.0pt 72.0pt 72.0pt;
}

div.WordSection1 {
  page: WordSection1;
}

.info-panel-container {
  max-height: 75vh;
}

.pricing-section {
  padding: 1em;
  &:not(:first-child) {
    margin-top: 0;
  }
  h2 {
    margin-bottom: 0;
  }
  >.row {
    margin: 0;
  }
  .labels {
    label {
      padding: .3em .7em;
      margin: 0;
      
    }
  }
  .group:not(:first-child) {
    margin-top: 15px;
  }
  .group:first-child {
    margin-top: 1em;
  }

  .group.example {
    border: 1px solid $secondary;
    border-radius: 5px;
    padding: 15px 0;
    margin-top: 40px;
    
    .heading {
      font-size: 1.25em;
      font-weight: bold;
      text-align: left;
      display: flex;
      color: $secondary;
      justify-content: flex-start;
      align-items: center;
      margin-left: -5px;
      position: absolute;
      top: -34px;
      left: 10px;
      background: white;
      padding: 0 .5em;
      i {
        margin-right: 5px;
      }
    }
    &.red {
      border: 1px solid $primary;
      .heading {
        color: $primary;
      }
    }
    &.other {
      margin-top: 0;
      border: 1px solid transparent;
    }
  }
  .pricing-table {
    border: 1px solid $secondary;
    border-radius: 5px;
    &.example {
      border: 1px solid transparent;
    }
    .table-header {
      padding: .3em .7em;
      text-align: right;
      &.blue {
        background: $secondary;
        color: white;
      }
      &.red {
        background: $primary;
        color: white;
      }
    }
    .table-body {
      div {
        padding: .3em .7em;
        text-align: right;
      }
    }
  }
}
.assessment-table {
  background: #fff;
  table-layout: fixed;
}

.assessment-table thead tr td:first-child,
.assessment-table tbody tr td:first-child {
  text-align: left;
}

.assessment-table thead tr td:last-child,
.assessment-table tbody tr td:last-child {
  text-align: center;
}