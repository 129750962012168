@import "./global.scss";
@import "./variables.scss";

.app-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  .app-sidebar {
    position: relative;
    height: 100%;
    min-width: 260px;
    max-width: 260px;
    padding: 0 1em;
    background: $sidebarBackground;
    border-radius: $sidebarCorners;
  }
  .app-main {
    position: relative;
    background: $mainBackground;
    width: calc(100vw - #{$sidebarWidth});
    height: 100vh;
  }
}
