@import 'global';

.login-page {
  background: url('../images/automotive-glass-technician.png');
  background-size: cover;
  background-color: $secondary;
  background-position: 50% 50%;
  z-index: 10;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;

  &::before {
    content: '';
    background: url('../images/europe-coverage-map.png');
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat no-repeat;
    background-position-x: 150%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.2;
  }
}

.login-container {
  width: 90vw;
  max-width: 500px;
  background: #fff;
  border-radius: $containerBorderRadius;
  padding: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 25px -5px rgba(0, 0, 0, 0.7);
  z-index: 20;
  .loader {
    margin: 15px 0 0 0;
  }
  .form-groups {
    flex: 1;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 0px;
    .form-group {
      .input-group-prepend {
        padding: 0.25em 0.75em;
        background: $secondary;
        color: #fff;
        border-radius: 5px 0 0 5px;
      }
      input {
        border-radius: 0 5px 5px 0 !important;
        border-color: $secondary;
      }
    }
  }
  .login-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    img {
      height: 4rem;
    }
  }
  .login-button,
  .login-button:focus,
  .login-button:active {
    background: $primary;
    border-color: $primary;
    padding: 0.75em 2em;
    margin-top: 15px;
    &:hover {
      background: darken($primary, 20%);
      border-color: darken($primary, 20%);
    }

    &:disabled {
      background: #ccc;
      color: #666;
      border-color: #ccc;
    }
  }
}
