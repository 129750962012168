
.Toastify__toast {
  display: flex;
  align-items: center;
  .Toastify__close-button {
    height: 100%;
    background: rgba(255,255,255,0.8) !important;
    color: $brandSecondary;
    opacity: 0.8;
    border: none !important;
    padding: .75em 10px !important;
    line-height:2em;
    &:hover {
      opacity: 1 !important;
    }
    &:active, &:focus {
      opacity: 0.9 !important;
    }
  }
  .toast-message {
    display: flex;
    align-items: center;
    i {
      margin-right: 8px;
    }
    line-height: 1em;
  }
  
  &.Toastify__toast--success {
    background:  $success !important;
    .Toastify__close-button {
      color: $success;
    }
  }
  
}