// Variables
$checkbox-size: 35px;
$checkbox-border: #cecece;
$checkbox-selected: #02BB72; // Primary colour
$color-gray-one: gray;

.checkbox{
  position: absolute;
  opacity: 0;

  & + input{
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: $checkbox-size;
    padding-left: $checkbox-size + 10;
    font-size: 20px;
    font-family: sans-serif;
    font-style: normal;
    color: #666;
  }

  // Text
  & + label{
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: $checkbox-size;
    padding-left: $checkbox-size + 10;
    font-size: 20px;
    font-family: sans-serif;
    font-style: normal;
    color: #666; // Label colour
    
    // Checkbox
    &:before{
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      content: "";
      background: transparent;
      width: $checkbox-size;
      height: $checkbox-size;
      
      box-shadow: inset 4px 4px 4px 4px $checkbox-border;
      border-radius: 4px;
      transition: 200ms ease-in-out all;
    }
    
    // Tick
    &:after{
      position: absolute;
      display: block;
      top: 8px;
      left: 14px;
      content: "";
      width: 8px;
      height: 16px;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      transform: rotate(45deg);
      
      transition: 300ms ease-in-out all;
    }
  }
  
  // Hover
  & + label:hover{
    color: #333;
  }
  
  // Focus
  & + label:focus{
    outline: none;
  }
  
  // Checked
  &:checked{
    & + label{
      &:before{
        background: $checkbox-selected;
        box-shadow: none;
      }
      &:after{
        border-color: white;
      }
    }
  }
  
  // Disabled
  &:disabled{
    & + label{
      &:before{
        background: #f2f2f2; // Disabled colour
        box-shadow: none;
        box-shadow: inset 4px 4px 4px 4px $checkbox-border;
      }
      &:after{
        border-color: transparent;
      }
    }
  }
  
  // Disabled Checked
  &:checked:disabled{
    & + label{
      &:before{
        background: #f2f2f2;
        box-shadow: none;
      }
      &:after{
        border-color: #02BB72;
      }
    }
  }
}

// Additional demo styling
.form-checkbox{
  position: relative;
  display: block;
  margin: 0 0 15px;
}

.picker {
    grid-column: 6/10;
    grid-row: 2/4;
    padding-bottom: 5px;
    input {
      font-size: 20px;
      width: 100%;
      font-family: "Roboto Condensed";
      font-weight: bold;
      src: '../../../images/calendar.png';
      color: $color-gray-one;
      text-align: left;
      border: none;
      background-color: transparent;
      border-bottom: 2px solid #E3B924;   
      background: url("../../src/images/calendar.png") no-repeat right;
      background-size: 20px;
      &:focus {
        outline: none;
      }
    }
  }

  .fileDrop {
    background: #f5f5f5;
    border: 1px dashed #c2c2c2;
    border-radius: 3px;
    text-align: center;
    padding: 36px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
  }
  
  .fileDrop:hover {
    background: rgb(194, 243, 194);
    border: 1px dashed #333;
  }

  .input {
    position: absolute;
    opacity: 20;
      cursor: pointer;
      font-size: 13px;
      font-family: sans-serif;
      font-style: normal;
      color: #666;
      margin-top: -20px;
      // position: absolute;
      display: block;
      left: 0;
      top: 0;
      content: "";
      background: transparent;
      width: $checkbox-size;
      height: $checkbox-size;
      
      box-shadow: inset 4px 4px 4px 4px $checkbox-border;
      border-radius: 4px;
      transition: 200ms ease-in-out all;
  }

  .form-input{
    position: absolute;
    display: block;
    margin: -21px 0 0;
  }

  .side-panel-overlay-sent {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    pointer-events: none;
    background: transparent;
    opacity: 1;
    transition: 0.1s all ease-in-out;
    &.open {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .side-panel-sent {
    position: absolute;
    width: 40vw;
    height: 80vh;
    top: 8vh;
    right: 0;
    box-shadow: -1px 0px 15px -4px;
    border-radius: 15px 0 0 15px;
    padding: 0px 10px;
    background: white;
    z-index: 200;
    transition: all 0.15s ease-in-out;
    transform: translateX(100%);
    z-index: 200;
    .close-btn,
    .close-btn:hover,
    .close-btn:active,
    .close-btn:focus {
      background: transparent !important;
      background-color: transparent !important;
      color: #444 !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      font-size: 32pt;
      line-height: 18pt;
      opacity: 0.8;
      transition: 0.1s opacity ease-in-out;
      padding: 0px 15px 0px 0px !important;
      min-width: fit-content;
  
      &:hover {
        opacity: 1;
      }
      &:active {
        opacity: 0.6;
      }
    }
    &.open {
      transform: translateX(0);
    }
  
    .claim {
      margin-top: 10px;
      height: 50vh;
    }
  }

  .user-cost-table-input {
    width: 180px;
    margin-right: 40px;
  }