@import "./global.scss";

$sidebarButtonsPadding: 0;
$sidebarButtonBorderRadius: 0;
$sidebarButtonHeight: 75px;
$sidebarButtonBackground: #ddd;
$sidebarButtonColor: $sidebarTextColor;

.app-sidebar {
  transition: 0.1s all ease-in-out;
  .logo {
    max-width: 100%;
    width: 100%;
    padding: 0.5em 1em !important;
    margin: 0;
    display: flex;
    background: transparent;
    border: none !important;
    outline: none !important;
    transition: 0.1s all ease-in-out;
    align-items: center;
    justify-content: flex-start;
    color: $sidebarTextColor;
    margin-top: 15px;
    h2 {
      font-size: 1.5em;
      text-align: left;
      line-height: 1;
      margin: 0;
      margin-left: 0.5em;
    }
    img {
      max-width: 100%;
      max-height: 25px;
      height: 25px;
    }
    &:hover {
      opacity: 0.8;
    }
  }

  .sidebar-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: $sidebarButtonsPadding $sidebarButtonsPadding
      $sidebarButtonsPadding 0;
    padding-bottom: 1.5em;
    color: $sidebarTextColor;
    .new-claim-btn {
      box-shadow: none !important;
      outline: none !important;
      outline: 2px solid transparent;
      background: $brandPrimary !important;
      margin: 1em 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $sidebarButtonColor;
      transform: skewX(-7.5deg);
      padding: 8px 0.75em !important;
      text-align: center;
      min-height: 40px;
      width: auto;
      min-width: 40px;
      max-height: 40px;
      height: 40px;
      overflow: hidden;
      transition: 0.1s all ease-in;

      .btn-icon {
        background: $sidebarButtonColor;
        padding: 5px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 3px;
        margin: 0;
        top: 2px;
        bottom: 2px;
        height: 33px;
        width: 33px;
        text-align: center;
        transition: 0.1s all ease-in;

        img,
        i {
          transition: 0.1s all ease-in;
          color: $brandPrimary;
          margin: 0;
          padding: 0;
        }
      }

      .sidebar-btn-text {
        padding-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        white-space: nowrap;
        margin-top: -2px;
      }

      &:hover {
        outline: 2px solid white;
        .btn-icon {
          width: 97%;
          top: 2px;
          left: 3px;
          bottom: px;
          right: 5px !important;
          justify-content: flex-start;
          img,
          i {
            margin: 0;
            padding: 0;
          }
        }
        .sidebar-btn-text {
          color: $brandPrimary;
          z-index: 1000;
        }
      }
    }
    .version {
      color: $sidebarTextColor;
      font-size: 0.8em;
      opacity: 0.5;
      text-align: center;
    }
  }

  .logout-btn {
    color: white !important;
    border: none !important;
    color: white !important;
    font-size: 1rem !important;
    margin-top: 10px !important;
  }

  .sidebar-buttons {
    display: flex;
    flex-direction: column;
    padding: $sidebarButtonsPadding $sidebarButtonsPadding
      $sidebarButtonsPadding 0;
    padding-bottom: 1.5em;

    .spacer {
      height: 25px;
    }
    .sidebar-button {
      height: $sidebarButtonHeight;
      border-radius: 0 !important;
      border: 0 !important;
      color: $sidebarButtonColor;
      outline: 0;
      margin-top: 0;
      background-color: transparent;
      box-shadow: none !important;
      font-weight: normal !important;
      padding: 0.5em 2em !important;
      display: flex;
      flex-direction: row;
      margin: 0 -1em;
      align-items: center;
      &:hover {
        background: darken($sidebarActiveBackground, 2%);
        color: $sidebarTextColor !important;
      }
      &:focus {
        background: transparent;
      }
      &:active {
        background: $sidebarActiveBackground;
      }
      &.active {
        background: $mainBackground !important;
        color: $sidebarBackground !important;
        opacity: 1;
      }
      .sidebar-btn-main {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        .sidebar-btn-text {
          margin-left: 10px;
          font-size: 20px;
        }
      }
      .sidebar-btn-meta {
        display: flex;
        margin-left: 0;
        margin-right: 0;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 0.75em;
        .meta-text {
          opacity: 0.6;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: left;
          text-transform: uppercase;
          padding-left: 12px;
        }
      }
    }
  }

  &.compact {
    width: $sidebarWidth;
    min-width: $sidebarWidth;
    padding: 0;
    .logo {
      justify-content: center;
      padding: 0.5em 1em !important;
      h2 {
        display: none;
      }
    }
    .sidebar-button {
      justify-content: center;
      .sidebar-btn-main {
        display: none;
      }
    }
    .sidebar-bottom {
      .version {
        color: #fff;
        font-size: 1em;
        opacity: 0.5;
        text-align: center;
        display: flex;
        flex-direction: column;
      }
      .new-claim-btn {
        max-width: 100%;
        .sidebar-btn-text {
          width: 0;
          overflow: hidden;
          display: none;
        }

        &:hover {
          .btn-icon {
            width: inherit;
          }
        }
      }
    }
  }
}
