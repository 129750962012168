.spin {
  animation: rotate 1s linear infinite;
  transform-origin: 50% 50%;
  line-height: 1px; /* hack for spinners not rotating on center in chrome */
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}