@import "./global.scss";

$inactiveColor: #c0cce6;

h1.form-title {
  font-size: 2.5em;
  margin: 0;
  line-height: 1;
}
p.subtitle {
  margin: 0;
  font-size: 1.25em;
}

ul.nav-tabs {
  display: flex;
  border-bottom: none;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 10px;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    background-color: $inactiveColor;
    border-radius: 2px;
    padding: 0px 60px;
    top: -9px;
  }
  li.nav-item {
    border: none;
    box-shadow: none;
    position: relative;

    a.nav-link {
      border: none;
      background-color: transparent;
      border-radius: 0.5em;
      color: $inactiveColor;
      cursor: pointer;
      &::before {
        content: "";
        width: 26px;
        height: 26px;
        position: absolute;
        border: 2px solid $inactiveColor;
        background-color: #fff;
        border-radius: 100%;
        left: 50%;
        top: -19px;
        margin-left: -13px;
        transition: 0.1s all ease-in;
      }
      &.invalid {
        color: $danger;
        &::before {
          border: 4px solid $danger;
        }
      }
      &.ready {
        color: $success;
        &::before {
          border: 6px solid $success;
        }
      }
      &.active {
        color: $secondary;
        &::before {
          border: 4px solid $secondary;
        }
      }
    }
  }
}

.claim-form-body {
  max-height: 80vh;
}

.claim-form-buttons {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 10vh;
  bottom: 60px;
  right: 0px;
  width: 50%;
  padding: 5px 35px;

  button:not(:first-child) {
    margin-left: 15px;
  }
}

.claim-buttons {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.section-warnings {
  text-align: right;
  padding: 20px 0;
  p {
    margin: 0;
    .section-warning-badge {
      margin-left: 5px;
      background-color: $primary;
      &.capitalize {
        text-transform: capitalize;
      }
    }
  }
}
