@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '~react-phone-number-input/style.css';
@import './variables.scss';
@import './Animations.scss';
@import './Inputs.scss';
@import './Modals.scss';
@import './Toasts.scss';

body {
  background: $mainBackground;
}

::-webkit-scrollbar {
  width: 0.4em;
}

::-webkit-scrollbar-track {
  background-color: white;
  box-shadow: none !important;
}

::-webkit-scrollbar-thumb {
  background-color: #d3d3d3b0 !important;
  outline: none !important;
  border-radius: 6px;
  width: 6px;
  right: 2px;
  position: absolute;
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.flex {
    display: flex;
    &.center {
      align-items: center;
      justify-content: center;
    }
  }
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.no-margin {
  margin: 0;
}

.padded-container {
  padding: 15px;
}

button {
  border-radius: 5px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  button.empty-state-back {
    margin-top: 2em;
    border-radius: 4px;
    border-width: 1px;
  }
}

@function inputFrameColor($color, $amount) {
  @return lighten($color, $amount);
}

@mixin formBorder($color, $amount: 70%) {
  border-color: inputFrameColor($color, $amount);
}

.form-control,
.DateInput_input,
.google-places-autocomplete__input,
.PhoneInputInput {
  border-radius: 5px;
  width: 100%;
  box-shadow: none;
  padding: 6px 12px;
  @include formBorder($secondary);
  border-width: 1px !important;
}

.select-dropdown {
  div {
    @include formBorder($secondary);
    color: #495057;
    font-size: 1rem;

    &:hover {
      @include formBorder($secondary, 60%);
    }

    svg {
      fill: inputFrameColor($secondary, 60%);
    }
  }
}

.width-100 {
  width: 100%;
}

.width-120 {
  min-width: 120px;
}

.select-dropdown-disabled {
  @extend .select-dropdown;
  div {
    background-color: #e9ecef !important;
  }
}

.PhoneInput {
  box-shadow: none !important;
  @include formBorder($secondary);
  .phone-country-dropdown {
    border-radius: 5px 0 0 5px !important;
    > div {
      @include formBorder($secondary);
      border-radius: 15px 0 0 5px !important;
      svg {
        fill: inputFrameColor($secondary, 60%);
      }
      > div:first-child {
        min-width: 70px;
      }
    }
  }
  .PhoneInputInput {
    border-radius: 0 5px 5px 0;
    box-shadow: none !important;
    @include formBorder($secondary);
    border-left: none;
  }
}

.currency-dropdown {
  border-radius: 5px 0 0 5px !important;
  > div:nth-child(2),
  div:nth-child(1) {
    @include formBorder($secondary);
    border-radius: 5px 0 0 5px !important;
    width: 100px;
    svg {
      fill: inputFrameColor($secondary, 60%);
    }
  }
}

.input-group {
  background: white;
  .input-group-text {
    background: transparent;
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
    @include formBorder($secondary);
    &::after {
      content: '';
      height: 80%;
      position: absolute;
      right: 0;
      top: 10%;
      width: 1px;
      background: lighten($secondary, 70%);
    }
  }
  &.disabled {
    .input-group-prepend {
      background: #e9ecef !important;
    }
  }
  input {
    border-left: none;
  }
}

@mixin buttonBaseDarker($color) {
  border-style: solid;
  border-color: darken($color, 10%) !important;
  border-width: 0 0 1px 0;
  background-color: $color;
  outline: none !important;
  box-shadow: none !important;
}

@mixin buttonBaseLighter($color) {
  border-style: solid;
  border-color: ligten($color, 10%) !important;
  border-width: 0 0 1px 0;
  background-color: $color;
  outline: none !important;
  box-shadow: none !important;
}

@mixin buttonDarker($color, $amount) {
  @include buttonBaseDarker($color);
  &:focus {
    @include buttonBaseDarker($color);
  }
  &:hover {
    background: darken($color, $amount);
  }
  &:active {
    background: darken($color, $amount + 2);
    border-bottom-color: $color !important;
  }
  &:disabled {
    border-bottom-color: transparent !important;
  }
}

@mixin buttonLighter($color, $amount) {
  @include buttonBaseLighter($color);
  &:focus {
    @include buttonBaseLighter($color);
  }
  &:hover {
    background: lighten($color, $amount);
  }
  &:active {
    background: lighten($color, $amount + 2);
    border-bottom-color: $color !important;
  }
  &:disabled {
    border-bottom-color: transparent !important;
  }
}

@mixin buttonOutlineBase($color) {
  border-style: solid;
  border-color: $color !important;
  border-width: 1px;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  color: $color !important;
  &:hover {
    background-color: $color !important;
    color: white !important;
  }
}

@mixin buttonOutline($color, $amount) {
  @include buttonOutlineBase($color);
}

button {
  border-radius: 4px !important;
  font-weight: 700 !important;
  padding: 0.75em 2.5em !important;

  &.btn-primary {
    @include buttonDarker($primary, 5%);
  }
  &.btn-secondary {
    @include buttonDarker($secondary, 5%);
  }
  &.btn-outline-secondary {
    @include buttonOutline($secondary, 5%);
  }
  &:disabled {
    pointer-events: none;
  }
}

.google-places-autocomplete__suggestions-container {
  background: #fff;
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.2);

  .google-places-autocomplete__suggestion {
    padding: 10px 10px;

    &:hover {
      background: lighten($secondary, 75%);
      cursor: pointer;
    }
  }
}

label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field-limit-indicator {
  color: #ccc;
  font-size: 0.85em;
  text-align: right;
  float: right;
}

.btn.no-margin-v {
  margin-top: 0;
  margin-bottom: 0;
}

.logout-button-sidebar {
  padding: 0 15px !important;
  padding-top: 10px !important;
  background: transparent !important;
  color: white;
  border: none !important;
}

.logout-button {
  padding: 0 15px !important;
  background: transparent !important;
  color: $brandSecondary;
  border: none !important;

  &:hover {
    color: $brandPrimary;
  }
}

.relative {
  position: relative;
}

.file-types-header {
  color: $brandSecondary;
}

.files-attached-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #007bff;

  h3 {
    color: $brandSecondary;
  }

  .text {
    color: $brandSecondary;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.todo-toggle-div {
  display: flex;
  margin: 0px;
  align-items: center;
  justify-content: space-between;
  width: 90px;

  > label {
    margin: 0px !important;
  }
}

.currency-input-form-input {
  display: flex;

  > div:nth-child(2) {
    flex: 1;

    > input {
      border-radius: 0px 5px 5px 0px;
      border-left: 0px;
    }
  }

  > button {
    margin-left: 10px;
    padding: 5px 20px !important;
    height: 38px;
  }
}

.all-claims-list {
  height: 85vh;
  overflow-x: auto;
}

.all-users-list {
  height: 75vh;
  overflow-x: auto;
}

.search-div {
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-end;
  align-items: center;

  > i {
    margin-left: 15px;
    cursor: pointer;
  }
}

.search-input {
  width: 250px;
}

.search-btn {
  height: 38px;
  padding: 5px 25px !important;
  margin-left: 15px;
}

.pass-btn {
  height: 38px;
  padding: 5px 20px !important;
  margin-left: 20px;
}

.usersContainer {
  padding: 20px;
}

.create-user-form {
  display: flex;
  align-items: center;

  > label {
    margin-right: 10px;
    margin-bottom: 0px;
  }
}

.date-form {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    > label {
      margin-right: 10px;
      margin-bottom: 0px;
    }
  }
}

.date-input {
  width: 180px;
  height: 40px;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 5px;
  border-radius: 5px;
  font-size: 1rem;
  color: #495057;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #007bff40;
  }
}

.user-input {
  width: 250px;
  margin-right: 40px;
}

.password-input {
  width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.user-btn {
  height: 38px;
  padding: 5px 25px !important;
}

.trash-user {
  color: $brandPrimary;
  padding-left: 3px;
  cursor: pointer;
}

.user-loader {
  color: $brandSecondary;
  margin-left: 3px;
}

.reset-user {
  color: $brandSecondary;
  padding-left: 3px;
  cursor: pointer;
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  width: 12px !important;
  height: 12px !important;
}

.react-datepicker__navigation {
  cursor: pointer !important;
  padding: 0 !important;
}

.rate-text {
  font-size: 12px;
  font-weight: bold;
  color: #0f2453;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.rate-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
}
